import { Asset, LastKnownLocation, PortableAssetTool, SelectedAsset } from '../../types';
import { Dispatch, SetStateAction } from 'react';
import { GridRowData } from '@material-ui/data-grid';
import { StrongFeatureHolder } from '../../../../hooks/geomoby/useLiveMapLoader';
import { Point } from 'ol/geom';
import { AssetState, MicrofenceData } from '../../Messages';
import { LocationDisplayType, LocationSearchData } from '../../Toolbar/LocationSearch';
import { BeaconLatestLocation } from './BeaconLatestLocation';
import { AssetLastLocation } from './AssetLastLocation';
import { getSensedBeacon } from '../../Helpers';

export const AssetLatestLocation = ({
  assetStates,
  microfences,
  activeAssets,
  range,
  setRange,
  now,
  selectedDevice,
  setSelectedDevice,
  selectedGPSTracker,
  setSelectedGPSTracker,
  selectedTool,
  setSelectedTool,
  selectedBeacon,
  setSelectedBeacon,
  setSelectedMicrofence,
  selectedAsset,
  setSelectedAsset,
  setLocationDisplay,
  setLocationSearchData,
}: {
  assetStates: AssetState[];
  microfences: StrongFeatureHolder<Point, MicrofenceData>[];
  activeAssets: SelectedAsset[];
  range: [Date | null, Date | null];
  setRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  now: () => Date;
  selectedDevice: Asset | undefined;
  setSelectedDevice: Dispatch<Asset | undefined>;
  selectedGPSTracker: Asset | undefined;
  setSelectedGPSTracker: Dispatch<Asset | undefined>;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  selectedTool: PortableAssetTool | undefined;
  setSelectedTool: Dispatch<PortableAssetTool | undefined>;
  selectedBeacon: Asset | undefined;
  setSelectedBeacon: Dispatch<Asset | undefined>;
  selectedAsset: SelectedAsset | undefined;
  setSelectedAsset: Dispatch<SelectedAsset | undefined>;
  setLocationDisplay: Dispatch<LocationDisplayType | undefined>;
  setLocationSearchData: Dispatch<LocationSearchData | undefined>;
}) => {
  const isActiveBeacon = selectedAsset && (selectedBeacon || selectedTool);
  const isInactiveBeaconOrSomeOtherAsset =
    (!selectedAsset && (selectedBeacon || selectedTool)) || selectedDevice || selectedGPSTracker;

  return (
    <>
      {isActiveBeacon && (
        <BeaconLatestLocation
          sensedBeacon={getSensedBeacon(selectedAsset, assetStates)}
          microfences={microfences}
          activeAssets={activeAssets}
          setSelectedDevice={setSelectedDevice}
          setSelectedGPSTracker={setSelectedGPSTracker}
          setSelectedMicrofence={setSelectedMicrofence}
          setSelectedTool={setSelectedTool}
          setSelectedBeacon={setSelectedBeacon}
          setSelectedAsset={setSelectedAsset}
        ></BeaconLatestLocation>
      )}

      {isInactiveBeaconOrSomeOtherAsset && (
        <AssetLastLocation
          asset={selectedBeacon ?? selectedDevice ?? selectedGPSTracker ?? (selectedTool as Asset)}
          activeAssets={activeAssets}
          range={range}
          setRange={setRange}
          now={now}
          setLocationDisplay={setLocationDisplay}
          setLocationSearchData={setLocationSearchData}
          setSelectedTool={setSelectedTool}
          setSelectedBeacon={setSelectedBeacon}
          setSelectedAsset={setSelectedAsset}
          setSelectedDevice={setSelectedDevice}
          setSelectedGPSTracker={setSelectedGPSTracker}
          setSelectedMicrofence={setSelectedMicrofence}
          microfences={microfences}
        ></AssetLastLocation>
      )}
    </>
  );
};
