import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Header } from '../../../../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../../../../store/auth';
import { TRIGGERS_URL } from '../../../../../store/url';
import { CID, PID, SUPER_ADMIN } from '../../../../../store/user';
import { DEFAULT_BUFFER_METERS, FRESH, FRESH_LAYER } from '../../../../../util/constants';
import { FileInput } from '../../../../../util/FileInput/FileInput';
import { LayersProps, SearchListProps } from '../../Props';
import * as Buffer from 'buffer';
import { normaliseErrorMessage } from '../../../../../util/ErrorMessages';
import {
  BufferShapeType,
  CreateOrUpdate,
  EntityType,
  GeofenceEntityTypeId,
} from '../../../../../util/enums';
import { SaveResult, SAVE_NOTIFICATION } from '../../../../../store/notifications';
import { GeofenceFilter } from '../../../types';
import { GridRowData } from '@material-ui/data-grid';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import { PRIMARY } from '../../../../../Style/GeoMobyBaseTheme';
import { getFreshLayerName } from '../../../Helpers';

export const useUploadLayer = (
  props: SearchListProps & {
    paginateGeofences: (filter: GeofenceFilter) => Promise<void>;
    uploadLayerJson: boolean;
    setUploadLayerJson: Dispatch<SetStateAction<boolean>>;
  },
) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const isSuperAdmin = useAtomValue(SUPER_ADMIN);

  const [bufferParams, setBufferParams] = useState<{ shape: BufferShapeType; metres: number }>({
    shape: BufferShapeType.Scaled,
    metres: 25,
  });
  const [dirtySave, setDirtySave] =
    useState<
      | {
          issue: string | null;
          skipped: string[];
        }
      | undefined
    >(undefined);
  const [jsonFile, setJsonFile] = useState<File>();
  const [selectedTab, setSelectedTab] = useState<CreateOrUpdate>(CreateOrUpdate.Create);
  const [uploading, setUploading] = useState<boolean>(false);

  const bufferMetresRef = useRef<HTMLInputElement>(null);

  const uploadFile = async (layerId: string) => {
    if (!jsonFile) return;
    const featuresFormData = new FormData();
    featuresFormData.append('file', jsonFile);

    try {
      if (selectedTab === CreateOrUpdate.Create) {
        const { success, created, skipped } = (
          await axios.post(
            `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/collection/upload-create?bufferMode=${bufferParams.shape.toLowerCase()}&bufferMetres=${
              bufferParams.metres
            }`,
            featuresFormData,
            authedConfig,
          )
        ).data;
        if (skipped.length > 0) {
          setDirtySave({
            issue: `${created} ${
              created === 1 ? 'geofence was' : 'geofences were'
            } created. The following geofences were skipped:`,
            skipped,
          });
        } else if (success) {
          setSaveNotification({
            id: SaveResult.SUCCESS,
            action: '',
            message: `created ${created} geofences`,
          });
          props.setUploadLayerJson(false);
        }
      } else {
        const { success, updated, skipped } = (
          await axios.patch(
            `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/collection/upload-update`,
            featuresFormData,
            authedConfig,
          )
        ).data;
        if (skipped.length > 0) {
          setDirtySave({
            issue: `${updated} ${
              updated === 1 ? 'geofence was' : 'geofences were'
            } updated. The following geofences were skipped:`,
            skipped,
          });
        } else if (success) {
          setSaveNotification({
            id: SaveResult.SUCCESS,
            action: '',
            message: `updated ${updated} geofences`,
          });
          props.setUploadLayerJson(false);
        }
      }
    } catch (error) {
      setDirtySave({
        issue: normaliseErrorMessage(error as AxiosError, EntityType.Layer),
        skipped: [],
      });
      setUploading(false);
      return;
    }

    setUploading(false);
    const olmap = props.mapState?.map;
    if (olmap) {
      const oldLayer = props.layerIds.find(lyr => lyr.id.startsWith(FRESH));
      if (oldLayer) {
        const newLayer = { id: layerId, name: oldLayer.name };
        props.setSelectedLayer(newLayer);
        props.setLayerIds([...props.layerIds.filter(lyr => !lyr.id.startsWith(FRESH)), newLayer]);
      }
      const layer = props.getLayerFromMap(olmap, layerId);
      if (layer) {
        layer.getSource()?.forEachFeature(feature => {
          layer.getSource().removeFeature(feature);
        });
      }
      props.knownExtentsRef.current = [];
      olmap?.getView().adjustZoom(-1);
      olmap?.getView().adjustZoom(+1);
    }
    props.paginateGeofences({ layerId });
  };

  const onSubmit = async () => {
    if (!jsonFile || !props.selectedLayer) return;
    let layerId: string | undefined = props.selectedLayer.id;
    const layerName = getFreshLayerName(props.layerIds);
    setUploading(true);
    if (props.selectedLayer?.id === FRESH_LAYER && props.mapState?.map) {
      layerId = await props.saveLayerChanges(props.mapState.map);
      if (!layerId || layerId === FRESH_LAYER)
        throw new Error('Group attempting to upload geofences to, does not exist.');
    }
    await uploadFile(layerId);
  };

  const changeTab = (event: SyntheticEvent, newTab: CreateOrUpdate) => {
    setSelectedTab(newTab);
  };

  useEffect(() => {
    if (!props.uploadLayerJson) return;
    setJsonFile(undefined);
  }, [props.uploadLayerJson]);

  useEffect(() => {
    setBufferParams({
      shape: BufferShapeType.Scaled,
      metres: 25,
    });
  }, [props.uploadLayerJson]);

  return (
    <>
      {isSuperAdmin && uploading && (
        <CircularProgress
          size={100}
          sx={{
            width: '200px',
            height: 'auto',
            position: 'absolute',
            zIndex: 1,
            positionArea: 'center',
          }}
        />
      )}
      <AppBar position="sticky" color="inherit">
        <Stack direction="row">
          <Tabs value={selectedTab} onChange={changeTab} variant="scrollable" scrollButtons="auto">
            <Tab label={CreateOrUpdate.Create} value={CreateOrUpdate.Create} />
            <Tab label={CreateOrUpdate.Update} value={CreateOrUpdate.Update} />
          </Tabs>
        </Stack>
      </AppBar>

      <Container>
        <Grid
          spacing={3}
          paddingY={2}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{
            padding: '60px 10px 10px 33px',
          }}
        >
          <Header icon={undefined}>{`Upload ${
            selectedTab === CreateOrUpdate.Create ? 'new' : 'existing'
          } geofences`}</Header>
          <Typography>Bulk upload via JSON file</Typography>
          <FileInput id="json" allowTypes={['.json', '.geojson']} onFileSet={f => setJsonFile(f)} />
        </Grid>

        <Grid
          container
          direction="column"
          alignItems="center"
          style={{
            width: 'calc(100% - 22px)',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            disabled={!jsonFile}
            style={{
              width: '25vw',
              maxWidth: '500px',
              minWidth: '255px',
            }}
            size="large"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Grid>

        {selectedTab === CreateOrUpdate.Create && (
          <Grid
            style={{
              marginTop: '10px',
            }}
          >
            <Tooltip title={'ONLY APPLICABLE TO BREACH ZONES'}>
              <Typography style={{ color: PRIMARY, margin: '20px 0px 10px 0px' }}>
                ONLY APPLICABLE TO BREACH ZONES
              </Typography>
            </Tooltip>
            <Tooltip title={'Set Buffer Zones'}>
              <Typography style={{ color: PRIMARY }}>Set Buffer Zones</Typography>
            </Tooltip>

            <Grid
              container
              style={{
                display: 'grid',
                gap: '2%',
                gridTemplateColumns: '49% 49%',
              }}
            >
              <ToggleButtonGroup
                color="primary"
                value={bufferParams.shape}
                sx={{
                  '& .MuiButtonBase-root': {
                    width: '50%',
                  },
                }}
                exclusive
                onChange={async e => {
                  const newShape = (e.target as EventTarget & HTMLInputElement)
                    .value as BufferShapeType;
                  setBufferParams({
                    ...bufferParams,
                    shape: newShape,
                  });
                }}
                aria-label="Platform"
              >
                <ToggleButton value={BufferShapeType.Circle}>{BufferShapeType.Circle}</ToggleButton>
                <ToggleButton value={BufferShapeType.Scaled}>{BufferShapeType.Scaled}</ToggleButton>
              </ToggleButtonGroup>

              <FormControl>
                <InputLabel id="offset">Offset (metres)</InputLabel>
                <OutlinedInput
                  id="offset"
                  label="Offset (metres)"
                  key={'offset'}
                  name={'offset'}
                  type={'number'}
                  inputRef={bufferMetresRef}
                  value={bufferParams.metres}
                  onChange={async ({ target: { value } }) => {
                    setBufferParams({
                      ...bufferParams,
                      metres: Number(value) < 1 ? 1 : Number(value),
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Container>

      <Dialog
        open={!!dirtySave}
        onClose={() => setDirtySave(undefined)}
        sx={{
          '& .MuiDialog-paper': {
            height: dirtySave?.skipped && dirtySave.skipped.length > 0 ? '48%' : 'auto',
          },
        }}
      >
        <DialogTitle>{dirtySave?.issue}</DialogTitle>
        {dirtySave?.skipped && dirtySave?.skipped.length > 0 && (
          <DialogContent>
            {dirtySave.skipped.map(fenceName => {
              return <p key={fenceName}>{fenceName}</p>;
            })}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={async () => {
              setDirtySave(undefined);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
