import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import olMap from 'ol/Map';
import { AUTHED_REQUEST_CONFIG } from '../../../../../store/auth';
import { TRIGGERS_URL } from '../../../../../store/url';
import { CID, PID } from '../../../../../store/user';
import { MICROFENCE, MICROFENCE_LAYER_ID, MICROFENCE_LAYER_LABEL } from '../../../BeaconUtils';
import {
  GeofenceFilter,
  MicrofenceFilter,
  MicrofenceType,
  SearchType,
  FenceZoneType,
  IBeacon,
  GeomobyOverride,
  NameId,
} from '../../../types';
import { SearchTypes, FenceZoneTypes, MicrofenceZoneTypes } from '../../../values';
import Polygon from 'ol/geom/Polygon';
import { MicrofenceData } from '../../../Messages';
import { Geometry, LineString, MultiPolygon, Point } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import { Clear, FilterList, LocationOn, Visibility, VisibilityOff } from '@mui/icons-material';
import { CenteredProgress } from '../../../../../Common/Sidebar';
import { Extent } from 'ol/extent';
import { transformExtent } from 'ol/proj';
import { Feature } from 'ol';
import { LocationSearch, LocationSearchData } from '../../../Toolbar/LocationSearch';
import { Map as OlMap } from 'ol';
import { useMobile } from '../../../../../util/useMobile';
import { option } from 'fp-ts';
import InputContainer from '../../../../Global/InputContainer';
import { getFenceLabel } from '../../../ControlPanels/labelling';
import { ReassignedFence } from '../../../FeatureStyles';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {
  BufferShapeType,
  EntityType,
  FenceGeometryType,
  FenceZone,
  MicrofenceEntity,
  MicrofenceZone,
  RequestType,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../../../util/enums';
import { GeomobyProperties } from '../Geofence/GeomobyProperties';
import { GeomobyOverrides } from '../Geofence/GeomobyOverrides';
import { useSelectedGeofence } from '../Geofence/SelectedGeofence';
import { useSelectedMicrofence } from '../Microfence/SelectedMicrofence';
import { SearchListProps } from '../../Props';
import { microfenceList } from '../../../../Microfence/MicrofenceList';
import { layerList } from '../../../../Layer/LayerList';
import { useUploadLayer } from '../Layer/UploadLayer';
import { useSelectedLayer } from '../Layer/SelectedLayer';
import { geometryTypeOfEntity } from '../../../commons';
import {
  ALL_GROUPS,
  ALL_LAYERS,
  FRESH,
  FRESH_LAYER,
  UNKNOWN_LAYER,
} from '../../../../../util/constants';
import { PRIMARY, truncationStyle, WHITE } from '../../../../../Style/GeoMobyBaseTheme';
import { geofenceList } from '../../../../Geofence/GeofenceList';

export const LoadingCircle = () => {
  return (
    <Grid
      container
      justifyContent={'center'}
      style={{
        marginTop: '100px',
      }}
    >
      <CenteredProgress />
    </Grid>
  );
};

export const SearchList = (props: SearchListProps) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const triggersUrl = useAtomValue(TRIGGERS_URL);

  const [bufferOffset, setBufferOffset] = useState<number | undefined>();
  const [bufferShape, setBufferShape] = useState<BufferShapeType>(BufferShapeType.Circle);
  const [deleting, setDeleting] =
    useState<EntityType.Geofence | EntityType.Layer | EntityType.Microfence | undefined>();
  const [fenceNameInput, setFenceNameInput] = useState<string>('');
  const [layerNameInput, setLayerNameInput] = useState<string>('');
  const [layerConflict, setLayerConflict] = useState<boolean>(false);
  const [microfenceIdInput, setMicrofenceIdInput] = useState<string>('');
  const [microfenceIBeaconIdentifier, setMicrofenceIBeaconIdentifier] = useState<IBeacon>({
    uuid: '',
    major: 0,
    minor: 0,
  });
  const [microfenceZoneInput, setMicrofenceZoneInput] = useState<MicrofenceZone | undefined>();
  const [reassigningToLayer, setReassigningToLayer] = useState<boolean>(false);
  const [reassignedLayerId, setReassignedLayerId] = useState<string>('');
  const [startScrolling, setStartScrolling] = useState<boolean>(false);
  const [uploadLayerJson, setUploadLayerJson] = useState<boolean>(false);

  const isMobile = useMobile();
  const layerNameRef = useRef<HTMLInputElement>(null);
  const microfenceIdRef = useRef<HTMLInputElement>(null);
  const microfenceUUIDRef = useRef<HTMLInputElement>(null);
  const microfenceMajorRef = useRef<HTMLInputElement>(null);
  const microfenceMinorRef = useRef<HTMLInputElement>(null);
  const isPaginatingRef = useRef<boolean>(false);
  const pageRef = useRef<number>(1);

  const getGeofence = useCallback(
    async (fence: GridRowData) => {
      if (!props.selectedGeofence) return;
      props.setSelectedMicrofence(undefined);
      const type = geometryTypeOfEntity(props.selectedGeofence);
      if (!type) return;

      const geofence = (
        await axios.get<{
          id: string;
          name: string;
          points: { coordinates: Extent };
          geomobyProperties: Record<string, string>;
          geomobyOverrides: GeomobyOverride[];
        }>(
          `${triggersUrl}/${cid}/${pid}/geofences/${fence.layerId}/${type}/${fence.id}`,
          authedConfig,
        )
      ).data;

      if (!geofence) return;
      const newFence =
        type === FenceGeometryType.Polygon
          ? new Polygon(geofence.points.coordinates)
          : type === FenceGeometryType.Multipolygon
          ? new MultiPolygon(geofence.points.coordinates)
          : new LineString(geofence.points.coordinates);

      const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
      props.setExtent(extent as Extent);
      return geofence;
    },
    [cid, pid, triggersUrl, authedConfig, props],
  );

  const paginateGeofences = useCallback(
    async (filter: GeofenceFilter, refresh?: boolean) => {
      if (props.layerIds.length === 0) {
        props.setAvailableGeofences([]);
        props.setCount(0);
        return;
      }

      const filterAllLayers = filter?.layerId === ALL_LAYERS || filter?.layerId === UNKNOWN_LAYER;
      if (filterAllLayers) {
        filter.layerId = undefined;
      }
      if (pageRef.current === 1 && !startScrolling) {
        props.setIsLoading(true);
      }

      let filters = `?perPage=200`;
      Object.entries(filter ?? {}).map(([key, value]) => {
        if (key !== undefined && value !== undefined) {
          filters = filters.concat(
            `&${key}=${
              typeof value === 'object'
                ? Array.isArray(value)
                  ? String(value)
                  : value.id
                : value.toString()
            }`,
          );
        }
      });

      if (props.layerIds.length === 1 && props.layerIds[0].id === MICROFENCE_LAYER_ID) {
        props.setIsLoading(false);
        return;
      }

      const { geofences, count } = (
        await axios.get<{ geofences: GridRowData[]; count: number }>(
          `${triggersUrl}/${cid}/${pid}/geofences/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;

      // Update fences in the new page with their rightful geometry
      const currentLayerFromMap = (
        props.mapState?.map?.getAllLayers().filter(l => l instanceof VectorLayer) as VectorLayer<
          VectorSource<Geometry>
        >[]
      ).find(l => l.getSource().get('id') === filter.layerId) as VectorLayer<
        VectorSource<Geometry>
      >;
      const fencesFromMap = geofences.map(fence => {
        const fenceFromMap: Feature<Geometry> | undefined = currentLayerFromMap
          ?.getSource()
          .getFeatures()
          .find(f => f.get('id') === fence.id);
        return fenceFromMap?.getProperties() ?? fence;
      });

      let result = [];
      if (pageRef.current === 1) {
        if (filter.layerId && props.reassignedFences.length > 0 && !refresh) {
          result = fencesFromMap.filter(fence => {
            return (
              fence.layerId === filter.layerId &&
              !props.reassignedFences.find(f => f.id === fence.id) &&
              !props.deletedFenceIdsRef.current.find(id => id === fence.id)
            );
          });
        } else {
          result = fencesFromMap.filter(
            fence => !props.deletedFenceIdsRef.current.find(id => id === fence.id),
          );
        }
      } else {
        result = fencesFromMap.filter(
          fence => !props.deletedFenceIdsRef.current.find(id => id === fence.id),
        );
      }

      props.setAvailableGeofences(
        !refresh
          ? [
              ...result,
              ...props.freshGeofences.filter(
                fence => !props.deletedFenceIdsRef.current.find(id => id === fence.id),
              ),
            ]
          : result,
      );

      props.setCount(count);
      props.setIsLoading(false);
    },
    [cid, pid, triggersUrl, authedConfig, props, startScrolling],
  );

  const microfenceSort = useCallback((): GridRowData[] => {
    const olmap = props.mapState?.map;
    if (!olmap) return [];
    let updatedMicrofences = olmap
      ?.getAllLayers()
      ?.find(layer => layer instanceof VectorLayer && layer.get('id') === MICROFENCE_LAYER_ID)
      ?.getSource()
      ?.getFeatures()
      ?.map((fence: Feature<Geometry>) => fence.getProperties());
    Object.entries(props.microfenceFilter ?? {}).map(([key, value]) => {
      if (
        !(
          key === 'orderBy' ||
          key === 'order' ||
          key === 'microfenceProperties' ||
          typeof value === 'string'
        )
      ) {
        updatedMicrofences = updatedMicrofences.filter(
          (microfence: GridRowData) => microfence.microfenceType === (value as MicrofenceType).id,
        );
      }
      if (key === 'searchName') {
        updatedMicrofences = updatedMicrofences.filter((microfence: GridRowData) =>
          microfence.name?.toLowerCase().includes(String(value)?.toLowerCase()),
        );
      }
      if (key === 'searchId') {
        updatedMicrofences = updatedMicrofences.filter((microfence: GridRowData) => {
          return (
            (microfence.microfenceType === MicrofenceEntity.Beacon &&
              microfence.assetId.uuid?.toLowerCase().includes(String(value)?.toLowerCase())) ||
            ((microfence.microfenceType === MicrofenceEntity.Gateway ||
              microfence.microfenceType === MicrofenceEntity.Smartplug) &&
              (microfence.assetId.gatewayId?.toLowerCase().includes(String(value)?.toLowerCase()) ||
                microfence.assetId.smartplugId
                  ?.toLowerCase()
                  .includes(String(value)?.toLowerCase()))) ||
            (microfence.microfenceType === MicrofenceEntity.Device &&
              microfence.assetId.deviceId?.toLowerCase().includes(String(value)?.toLowerCase()))
          );
        });
      }
      if (key === 'microfenceProperties') {
        updatedMicrofences = updatedMicrofences.filter((microfence: GridRowData) => {
          const found = Object.entries(microfence.geomobyProperties).find(([k, v]) => {
            if (props.microfenceFilter?.microfenceProperties?.operator?.id === 'EQUAL_TO') {
              return (
                k === props.microfenceFilter?.microfenceProperties?.type?.value &&
                Number(v) === props.microfenceFilter?.microfenceProperties?.value
              );
            } else if (
              props.microfenceFilter?.microfenceProperties?.operator?.id === 'GREATER_THAN'
            ) {
              return (
                k === props.microfenceFilter?.microfenceProperties?.type?.value &&
                Number(v) > props.microfenceFilter?.microfenceProperties?.value
              );
            } else {
              return (
                k === props.microfenceFilter?.microfenceProperties?.type?.value &&
                Number(v) < props.microfenceFilter?.microfenceProperties?.value
              );
            }
          });
          if (!found) return;
          return microfence;
        });
      }
    });

    updatedMicrofences = updatedMicrofences.filter(
      (microfence: GridRowData) => !props.deletedFenceIdsRef.current.find(d => d === microfence.id),
    );

    updatedMicrofences =
      props.microfenceFilter?.order?.id === 'DESC'
        ? updatedMicrofences.sort((a: GridRowData, b: GridRowData) => {
            if (props.microfenceFilter?.orderBy?.id === 'microfenceType') {
              return String(b.microfenceType).localeCompare(String(a.microfenceType));
            } else {
              return String(b.name).localeCompare(String(a.name));
            }
          })
        : updatedMicrofences.sort((a: GridRowData, b: GridRowData) => {
            if (props.microfenceFilter?.orderBy?.id === 'microfenceType') {
              return String(a.microfenceType).localeCompare(String(b.microfenceType));
            } else {
              return String(a.name).localeCompare(String(b.name));
            }
          });

    props.setAvailableMicrofences(updatedMicrofences);
    return updatedMicrofences;
  }, [props]);

  const refreshFilter = useCallback(() => {
    props.setAvailableGeofences([]);
    microfenceSort();
    props.setCount(0);
    props.setGeofenceFilter(undefined);
    props.setMicrofenceFilter(undefined);
    pageRef.current = 1;

    if (props.searchType?.id === SearchTypeIDs.Geofences) {
      paginateGeofences({ layerId: props.selectedLayer?.id } as GeofenceFilter);
    }
  }, [microfenceSort, paginateGeofences, props]);

  const resetlayer = useCallback(async () => {
    if (props.selectedGeofence) {
      props.setSelectedLayer(
        props.layerIds.find(lyr => lyr.id === props.selectedGeofence?.layerId),
      );
      await paginateGeofences(
        {
          ...props.geofenceFilter,
          layerId: props.selectedGeofence.layerId,
        } as GeofenceFilter,
        true,
      );
    } else if (props.selectedMicrofence) {
      props.setSelectedLayer({ name: MICROFENCE_LAYER_LABEL, id: MICROFENCE_LAYER_ID });
      const olmap = props.mapState?.map;
      if (!olmap) return;
      props.setAvailableMicrofences(
        olmap
          ?.getAllLayers()
          ?.find(layer => layer instanceof VectorLayer && layer.get('id') === MICROFENCE_LAYER_ID)
          ?.getSource()
          ?.getFeatures()
          .map((fence: Feature<Geometry>) => fence.getProperties()),
      );
    }
  }, [props, paginateGeofences]);

  const saveAllChanges = useCallback(async () => {
    if (!props.mapState?.map) return;
    const olmap = props.mapState.map;
    props.setDrawType(undefined);
    if (props.layerIds.find(lyr => lyr.id === UNKNOWN_LAYER)) {
      props.setLayerIds(props.layerIds.filter(lyr => lyr.id !== UNKNOWN_LAYER));
    }

    const layerId = await props.saveLayerChanges(olmap);
    if (props.editing && !props.dirtySave.isDirty) {
      props.unsetEditing(olmap);
    }

    pageRef.current = 1;
    setLayerNameInput('');
    props.setReassignedFences([]);
    props.setCreateEditLayer(undefined);
    props.setCreateEditFence(undefined);
    setReassigningToLayer(false);
    setReassignedLayerId('');
    microfenceSort();
    if (props.searchType?.id === SearchTypeIDs.Geofences) {
      paginateGeofences(
        {
          ...props.geofenceFilter,
          layerId: layerId || ALL_LAYERS,
        } as GeofenceFilter,
        true,
      );
    }
  }, [props, microfenceSort, paginateGeofences]);

  const clearAll = () => {
    props.setAvailableGeofences([]);
    microfenceSort();
    props.setCount(0);
    props.setGeofenceFilter(undefined);
    props.setMicrofenceFilter(undefined);
    props.setSelectedLayer(undefined);
    pageRef.current = 1;
    props.setSearchType(undefined);
    props.deletedFenceIdsRef.current = [];
  };

  const cancelGeofence = useCallback(async () => {
    props.setCreateEditFence(undefined);
    setReassigningToLayer(false);
    setFenceNameInput('');
    setMicrofenceIdInput('');
    setMicrofenceZoneInput(undefined);
    setReassignedLayerId('');
    setBufferOffset(undefined);
    setBufferShape(BufferShapeType.Circle);
    if (!props.mapState?.map) return;
    const olmap = props.mapState.map;
    const bufferId = props.availableGeofences?.find(
      f => f.parentId === props.selectedGeofence?.id,
    )?.id;
    if (props.selectedGeofence?.zone === FenceZone.breach && bufferId) {
      props.unsetAsBreachZone(olmap, props.selectedGeofence, bufferId);
    } else if (props.selectedGeofence?.zone === FenceZone.cleared) {
      props.unsetZone(olmap, props.selectedGeofence);
    }

    if (props.selectedGeofence && !props.selectedGeofence?.id?.includes(FRESH)) {
      const { id, layerId, type } = props.selectedGeofence;
      const selectedType = geometryTypeOfEntity(props.selectedGeofence);
      if (!selectedType) return;

      const oldFence = (
        await axios.get<GridRowData>(
          `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${selectedType}/${id}`,
          authedConfig,
        )
      ).data;
      props.setDisplayGeomobyProperties(
        Object.entries(oldFence.geomobyProperties).map(([property, value], index) => {
          return {
            index: index as number,
            property: property as string,
            value: value as string,
          };
        }),
      );
      props.setDisplayGeomobyOverrides(oldFence.geomobyOverrides);
      props.setSelectedGeofence({
        ...oldFence,
        layerId,
        type,
      });
    }
  }, [authedConfig, cid, pid, props, triggersUrl]);

  const cancelMicrofence = useCallback(() => {
    props.setCreateEditFence(undefined);
    setFenceNameInput('');
    setMicrofenceIdInput('');
    setMicrofenceIBeaconIdentifier({
      uuid: '',
      major: 0,
      minor: 0,
    });
    setMicrofenceZoneInput(undefined);
  }, [
    props,
    setFenceNameInput,
    setMicrofenceIdInput,
    setMicrofenceIBeaconIdentifier,
    setMicrofenceZoneInput,
  ]);

  const cancelAllChanges = useCallback(async () => {
    pageRef.current = 1;
    props.deletedFenceIdsRef.current = [];
    props.setDrawType(undefined);
    if (!props.mapState?.map) return;
    const olmap = props.mapState?.map;
    if (props.editing) {
      props.unsetEditing(olmap);
    }
    setLayerNameInput('');
    await props.resetLayerChanges(olmap);
    cancelGeofence();
    cancelMicrofence();
    props.setReassignedFences([]);
    if (
      props.selectedLayer?.id === ALL_LAYERS ||
      props.selectedLayer?.id === FRESH_LAYER ||
      props.selectedLayer?.id === UNKNOWN_LAYER
    ) {
      props.setSelectedLayer(undefined);
    }
  }, [props, setLayerNameInput, cancelGeofence, cancelMicrofence]);

  const goBack = () => {
    const olmap = props.mapState?.map;
    if (!olmap) return;
    props.deselectFences(olmap);
    cancelMicrofence();
    props.setSelectedGeofence(undefined);
    setReassignedLayerId('');
    setBufferOffset(undefined);
    setBufferShape(BufferShapeType.Circle);
    props.setSelectedMicrofence(undefined);

    if (!props.selectedLayer) {
      props.setSearchType(undefined);
      props.layerIds?.forEach(layer => props.changeVisibility(olmap, layer.id, true));
      props.setSelectedLayer(undefined);
      props.setCreateEditLayer(undefined);
      props.setCreateEditFence(undefined);
      return;
    }

    if (props.searchType?.id === SearchTypeIDs.Geofences) {
      if (props.selectedLayer?.id === ALL_LAYERS || props.selectedLayer?.id === UNKNOWN_LAYER) {
        pageRef.current = 1;
        setStartScrolling(false);
        isPaginatingRef.current = false;
      }
      paginateGeofences({
        ...props.geofenceFilter,
        layerId: props.selectedLayer?.id,
      } as GeofenceFilter);
    } else if (props.searchType?.id === SearchTypeIDs.Microfences) {
      microfenceSort();
    }
  };

  const onClickLayer = async (layer: NameId) => {
    const olmap = props.mapState?.map;
    if (!olmap) return;

    if (layer?.id?.includes(FRESH)) return;
    props.setSelectedLayer(layer);
    pageRef.current = 1;
    await paginateGeofences({
      ...props.geofenceFilter,
      layerId: layer?.id,
    } as GeofenceFilter);

    if (layer?.id === ALL_LAYERS) {
      props.setSelectedGeofence(undefined);
      props.setSelectedMicrofence(undefined);
      props.deselectFences(olmap);
      props.setDrawType(undefined);
      props.layerIds?.forEach(layer =>
        props.changeVisibility(olmap, layer.id, layer.id !== MICROFENCE_LAYER_ID),
      );
      setLayerNameInput('');
    }
  };

  const onClickGeofence = async (fence: GridRowData) => {
    if (fence.id?.includes(FRESH) && fence.layerId === UNKNOWN_LAYER) {
      props.setOpenGenericDialog(true);
      return;
    }
    props.setDisplayGeomobyProperties(
      Object.entries(fence.geomobyProperties).map(([property, value], index) => {
        return {
          index: index as number,
          property: property as string,
          value: value as string,
        };
      }),
    );
    props.setDisplayGeomobyOverrides(fence.geomobyOverrides);
    fence.selected = true;
    props.setSelectedGeofence(fence);
    setFenceNameInput(fence.name);
    setReassignedLayerId('');
    setBufferOffset(undefined);
    setBufferShape(BufferShapeType.Circle);
    getGeofence(fence);
    props.setDrawType(undefined);
    const olmap = props.mapState?.map;
    if (!olmap) return;
    const foundFence = await props.findFeature(
      olmap,
      fence.id,
      fence.layerId,
      geometryTypeOfEntity(fence),
    );
    if (foundFence?.get('zone') === FenceZone.breach) {
      const bufferZone = props.availableGeofences.find(f => f.parentId === fence.id);
      if (bufferZone) {
        await props.findFeature(olmap, bufferZone.id, fence.layerId, FenceGeometryType.Polygon);
      }
    } else if (foundFence?.get('zone') === FenceZone.buffer) {
      const breachZone = props.availableGeofences.find(f => f.id === fence.parentId);
      if (breachZone) {
        await props.findFeature(olmap, breachZone.id, fence.layerId, FenceGeometryType.Polygon);
      }
    }
    foundFence?.set('selected', true);
    if (props.editing) {
      props.unsetEditing(olmap);
    }
  };

  const onClickMicrofence = async (microfence: GridRowData) => {
    const coords = microfence.point?.coordinates ?? microfence.geometry?.getExtent();
    const extent = transformExtent([...coords, ...coords], 'EPSG:4326', 'EPSG:3857');
    microfence.selected = true;
    props.setSelectedMicrofence(microfence);
    const olmap = props.mapState?.map;
    if (olmap) {
      const foundFence = await props.findFeature(
        olmap,
        microfence.id,
        MICROFENCE_LAYER_ID,
        FenceGeometryType.Microfence,
      );
      foundFence?.set('selected', true);
    }

    if (extent) {
      props.setExtent(extent as Extent);
    }
    props.setDisplayGeomobyProperties(
      Object.entries(microfence.geomobyProperties).map(([property, value], index) => {
        return {
          index: index as number,
          property: property as string,
          value: value as string,
        };
      }),
    );
    setFenceNameInput(microfence.name);
    const assetId = microfence.assetId;
    if (assetId.uuid && assetId.major && assetId.minor) {
      setMicrofenceIBeaconIdentifier({
        uuid: String(assetId.uuid),
        major: Number(assetId.major),
        minor: Number(assetId.minor),
      });
    } else if (assetId.deviceId || assetId.gatewayId || assetId.smartplugId) {
      setMicrofenceIdInput(assetId.deviceId ?? assetId.gatewayId ?? assetId.smartplugId);
    }
  };

  // Created a new layer.
  useEffect(() => {
    if (!props.createEditLayer) return;
    layerNameRef.current?.focus();
  }, [props, props.createEditLayer]);

  // Created a new Geofence or Microfence.
  useEffect(() => {
    if (props.createEditFence !== RequestType.Create) return;
    if (props.selectedGeofence || props.selectedMicrofence) {
      cancelGeofence();
      cancelMicrofence();
    }

    if (props.selectedGeofence) {
      if (props.selectedGeofence?.id.includes(FRESH)) {
        props.setDisplayGeomobyProperties(
          Object.entries(props.selectedGeofence?.geomobyProperties).map(
            ([property, value], index) => {
              return {
                index: index as number,
                property: property as string,
                value: value as string,
              };
            },
          ),
        );
        props.setDisplayGeomobyOverrides(props.selectedGeofence?.geomobyOverrides);
      }
    } else if (props.selectedMicrofence) {
      if (props.selectedMicrofence?.id.includes(FRESH)) {
        props.setDisplayGeomobyProperties(
          Object.entries(props.selectedMicrofence?.geomobyProperties).map(
            ([property, value], index) => {
              return {
                index: index as number,
                property: property as string,
                value: value as string,
              };
            },
          ),
        );
      }
    }
  }, [props, cancelGeofence, cancelMicrofence]);

  // Clicked a geofence or microfence from the map.
  useEffect(() => {
    if (!props.selectedFromMap) return;
    if (props.selectedGeofence) {
      props.setDisplayGeomobyProperties(
        Object.entries(props.selectedGeofence.geomobyProperties).map(([property, value], index) => {
          return {
            index: index as number,
            property: property as string,
            value: value as string,
          };
        }),
      );
      props.setDisplayGeomobyOverrides(props.selectedGeofence?.geomobyOverrides);
      setFenceNameInput(props.selectedGeofence.name);
      setBufferOffset(undefined);
      setBufferShape(BufferShapeType.Circle);
    } else if (props.selectedMicrofence) {
      cancelMicrofence();
      props.setDisplayGeomobyProperties(
        Object.entries(props.selectedMicrofence.geomobyProperties).map(
          ([property, value], index) => {
            return {
              index: index as number,
              property: property as string,
              value: value as string,
            };
          },
        ),
      );
      setFenceNameInput(props.selectedMicrofence.name);
    }
    props.setSelectedFromMap(false);
  }, [props, cancelGeofence, cancelMicrofence]);

  // Filter
  useEffect(() => {
    if (!props.refreshSearch) return;
    if (props.searchType?.id === SearchTypeIDs.Geofences) {
      pageRef.current = 1;
      paginateGeofences(
        { ...props.geofenceFilter, layerId: props.selectedLayer?.id } as GeofenceFilter,
        props.freshGeofences.length === 0,
      );
    }

    // TODO: This will have to do for microfence searching, until we have the correct end-point - LTP-478.
    if (props.searchType?.id === SearchTypeIDs.Microfences) {
      props.setSelectedLayer({ name: MICROFENCE_LAYER_LABEL, id: MICROFENCE_LAYER_ID });
      microfenceSort();
    }

    props.setRefreshSearch(false);
    props.setShowFilter(false);
  }, [props, paginateGeofences, refreshFilter, microfenceSort]);

  // Clear filter.
  useEffect(() => {
    if (props.clearFilter) {
      refreshFilter();
      props.setClearFilter(false);
    }
  }, [props, refreshFilter]);

  useEffect(() => {
    if (props.deselectFence && props.mapState?.map) {
      const olmap = props.mapState.map;
      props.setDeselectFence(false);
      if (!props.selectedLayer) {
        props.setSearchType(undefined);
        props.layerIds?.forEach(layer => props.changeVisibility(olmap, layer.id, true));
        props.setSelectedLayer(undefined);
        props.setCreateEditLayer(undefined);
        props.setCreateEditFence(undefined);
      }
    }
  }, [props]);

  return (
    <>
      <Grid
        style={{
          contentVisibility: props.showFilter ? 'hidden' : 'visible',
          margin: '20px',
        }}
      >
        {(props.selectedGeofence ||
          props.selectedMicrofence ||
          (!props.selectedGeofence &&
            !props.selectedMicrofence &&
            props.selectedLayer &&
            props.searchType?.id === SearchTypeIDs.Geofences)) && (
          <Button
            style={{
              marginBottom: '10px',
            }}
            disabled={
              props.selectedGeofence?.layerId === UNKNOWN_LAYER ||
              props.selectedGeofence?.previousLayer === UNKNOWN_LAYER ||
              props.selectedGeofence?.layerId === ALL_LAYERS ||
              props.selectedGeofence?.previousLayer === ALL_LAYERS ||
              props.isLoading
            }
            onClick={() => {
              const olmap = props.mapState?.map;
              props.setDrawType(undefined);
              if (props.editing && olmap) {
                props.unsetEditing(olmap);
              }
              if (props.selectedGeofence || props.selectedMicrofence) {
                goBack();
              } else if (
                !props.selectedGeofence &&
                !props.selectedMicrofence &&
                props.selectedLayer &&
                props.searchType?.id === SearchTypeIDs.Geofences
              ) {
                props.setSelectedLayer(undefined);
                props.setCreateEditLayer(undefined);
                props.setCreateEditFence(undefined);
                props.setLayerIds(props.layerIds.filter(lyr => lyr.id !== FRESH_LAYER));
                props.setLayersHaveChanged(false);
                props.setAvailableGeofences([]);
                props.setAvailableMicrofences([]);
              }
            }}
          >
            Back
          </Button>
        )}

        {/* Search type */}
        <Grid
          container
          direction="row"
          sx={
            props.selectedLayer?.id === MICROFENCE_LAYER_ID && !props.selectedMicrofence
              ? {
                  display: 'grid',
                  gap: isMobile ? '0%' : '2%',
                  gridTemplateColumns: isMobile ? '92% 10%' : '88% 10%',
                }
              : {}
          }
        >
          {!(
            props.selectedGeofence ||
            props.selectedMicrofence ||
            (props.selectedLayer && props.searchType?.id === SearchTypeIDs.Geofences)
          ) && (
            <FormControl
              fullWidth
              style={{
                width: '98%',
                marginBottom: '20px',
              }}
            >
              <InputLabel id="search-option">Search For</InputLabel>
              <Select
                fullWidth
                labelId="search-option"
                id="search-option"
                value={props.searchType?.value ?? ''}
                label="Search For"
                disabled={!!props.createEditLayer || props.mapIsLoading || props.layersHaveChanged}
                onChange={e => {
                  const search = SearchTypes.find(
                    l => l.value === (e.target.value as SearchTypeValue),
                  );
                  clearAll();
                  const olmap = props.mapState?.map;
                  props.setSearchType(search);
                  props.setShowGhostGeofences(false);

                  if (search?.id === SearchTypeIDs.Geofences && olmap) {
                    props.layerIds?.forEach(layer =>
                      props.changeVisibility(
                        olmap,
                        layer.id,
                        layer.id !== MICROFENCE_LAYER_ID,
                        layer.id === MICROFENCE_LAYER_ID ? 0 : 1,
                      ),
                    );
                  }
                  if (search?.id === SearchTypeIDs.Microfences && olmap) {
                    microfenceSort();
                    props.layerIds?.forEach(layer =>
                      props.changeVisibility(olmap, layer.id, layer.id === MICROFENCE_LAYER_ID),
                    );
                    props.setSelectedLayer({
                      name: MICROFENCE_LAYER_LABEL,
                      id: MICROFENCE_LAYER_ID,
                    });
                  }
                  if (search?.id === SearchTypeIDs.Locations && olmap) {
                    props.layerIds?.forEach(layer =>
                      props.changeVisibility(olmap, layer.id, true, 1),
                    );
                  }
                }}
              >
                {SearchTypes.filter(search => search.forEditing).map(search => (
                  <MenuItem key={search.id} value={search.value} disabled={search.disabled}>
                    <Tooltip title={search.value}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 30px)',
                        }}
                      >
                        {search.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {props.selectedLayer?.id === MICROFENCE_LAYER_ID && !props.selectedMicrofence && (
            <Tooltip title={'SHOW GEOFENCES'}>
              <IconButton
                style={{
                  color: props.showGhostGeofences ? PRIMARY : WHITE,
                  justifySelf: 'right',
                  width: 'fit-content',
                  height: 'fit-content',
                  marginTop: '12px',
                }}
                onClick={() => {
                  const olmap = props.mapState?.map;
                  const showAllFences = !props.showGhostGeofences;
                  if (olmap) {
                    props.layerIds?.forEach(layer =>
                      props.changeVisibility(
                        olmap,
                        layer.id,
                        layer.id === MICROFENCE_LAYER_ID ? true : showAllFences,
                      ),
                    );
                  }
                  props.setShowGhostGeofences(showAllFences);
                }}
              >
                {props.showGhostGeofences ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>
          )}
        </Grid>

        {/* Layer */}
        {useSelectedLayer({
          ...props,
          ...{
            setDeleting,
            setLayerConflict,
            layerNameInput,
            setUploadLayerJson,
            layerNameRef,
            pageRef,
            setLayerNameInput,
            cancelAllChanges,
            paginateGeofences,
          },
        })}

        {/* Filter button and count */}
        {!props.createEditLayer &&
          props.selectedLayer &&
          !(props.selectedGeofence || props.selectedMicrofence) &&
          !props.isLoading && (
            <Grid
              container
              direction="column"
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
              }}
            >
              <Grid item>
                <Button onClick={() => props.setShowFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Filter</span>
                  <FilterList />
                </Button>
                <Button onClick={() => props.setClearFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Clear</span>
                  <Clear />
                </Button>
              </Grid>

              <Grid
                item
                style={{
                  margin: '8px 10px 0px 0px',
                  justifySelf: 'end',
                }}
              >
                {props.searchType?.id === SearchTypeIDs.Geofences && (
                  <Typography>{`${props.count} ${
                    Number(props.count) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
                {props.searchType?.id === SearchTypeIDs.Microfences && (
                  <Typography>{`${props.availableMicrofences.length} ${
                    Number(props.availableMicrofences.length) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
              </Grid>
            </Grid>
          )}

        {/* Layers, Geofences, Microfences  */}
        {!props.createEditLayer && !(props.selectedGeofence || props.selectedMicrofence) && (
          <Grid
            container
            direction={'row'}
            style={{
              marginTop: '10px',
              height: 'fit-content',
              maxHeight: '1000px',
              overflowY: 'auto',
            }}
            onScroll={async e => {
              const target = e.target as HTMLTextAreaElement;
              if (target.scrollTop > 8000) {
                if (isPaginatingRef.current) {
                  target.scrollTop = 7800;
                  return;
                }
                pageRef.current += 1;
                setStartScrolling(true);
                isPaginatingRef.current = true;
                await paginateGeofences({
                  ...props.geofenceFilter,
                  layerId: props.selectedLayer?.id,
                } as GeofenceFilter);
                isPaginatingRef.current = false;
                target.scrollTop = 200;
              }
              if (startScrolling && target.scrollTop < 100) {
                if (isPaginatingRef.current) {
                  target.scrollTop = 300;
                  return;
                }
                if (pageRef.current > 1) {
                  pageRef.current -= 1;
                } else {
                  setStartScrolling(false);
                  return;
                }
                isPaginatingRef.current = true;
                await paginateGeofences({
                  ...props.geofenceFilter,
                  layerId: props.selectedLayer?.id,
                } as GeofenceFilter);
                isPaginatingRef.current = false;
                target.scrollTop = 7800;
              }
            }}
          >
            {/* Layers */}
            {layerList({
              title: 'Select a group',
              showList:
                !(props.selectedGeofence || props.selectedMicrofence) &&
                props.searchType?.id === SearchTypeIDs.Geofences &&
                !props.selectedLayer,
              availableLayers: [
                { name: ALL_GROUPS, id: ALL_LAYERS },
                ...props.layerIds.filter(lyr => lyr.id !== MICROFENCE_LAYER_ID),
              ],
              onClick: onClickLayer,
            })}

            {props.isLoading && LoadingCircle()}

            {/* Geofences */}
            {geofenceList({
              showList: !props.isLoading && props.searchType?.id === SearchTypeIDs.Geofences,
              availableGeofences: props.availableGeofences,
              onClick: onClickGeofence,
            })}

            {/* Microfences */}
            {microfenceList({
              showList: !props.isLoading && props.searchType?.id === SearchTypeIDs.Microfences,
              availableMicrofences: props.availableMicrofences,
              onClick: onClickMicrofence,
            })}
          </Grid>
        )}

        {/* Geofence */}
        {useSelectedGeofence({
          ...props,
          ...{
            bufferOffset,
            setBufferOffset,
            bufferShape,
            setBufferShape,
            setDeleting,
            fenceNameInput,
            setFenceNameInput,
            reassignedLayerId,
            setReassignedLayerId,
            pageRef,
            getGeofence,
            paginateGeofences,
            resetlayer,
            saveAllChanges,
          },
        })}

        {/* Microfence */}
        {useSelectedMicrofence({
          ...props,
          ...{
            setDeleting,
            fenceNameInput,
            setFenceNameInput,
            microfenceIdInput,
            setMicrofenceIdInput,
            microfenceIBeaconIdentifier,
            setMicrofenceIBeaconIdentifier,
            setMicrofenceZoneInput,
            pageRef,
            microfenceSort,
            resetlayer,
            saveAllChanges,
          },
        })}
      </Grid>

      {/* Location */}
      {props.searchType?.id === SearchTypeIDs.Locations && props.layerIds && (
        <>
          <div
            style={{
              width: '100px',
              alignSelf: 'start',
              margin: '-20px 0px -20px 20px',
            }}
          >
            <Tooltip title={'Search'}>
              <Typography variant="h5">Search</Typography>
            </Tooltip>
          </div>
          <div
            style={{
              margin: '20px 0px 0px 20px',
            }}
          >
            <LocationSearch
              isGeofenceEditor={false}
              setLocationSearchData={props.setLocationSearchData}
              currentCenter={props.currentCenter}
              setLocationDisplay={props.setLocationDisplay}
            ></LocationSearch>
          </div>

          {props.locationDisplay && (
            <Paper
              variant="outlined"
              style={{
                padding: '10px',
                height: 'fit-content',
                width: 'fit-content',
                margin: '20px',
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  '& .MuiTypography-root': {
                    marginLeft: '0px',
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    ...truncationStyle,
                  },
                  '& .MuiStack-root': {
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    justifyContent: 'start',
                  },
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                  <Box color="primary.main">
                    <LocationOn />
                  </Box>
                  <Tooltip title={props.locationDisplay.label}>
                    <Typography variant="h5">{props.locationDisplay.label}</Typography>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid
                style={{
                  marginLeft: '32px',
                }}
              >
                <Tooltip title={props.locationDisplay.coordinates}>
                  <Typography
                    style={{
                      marginLeft: '-7px',
                      color: WHITE,
                    }}
                  >
                    {`${props.locationDisplay.coordinates[0]}, ${props.locationDisplay.coordinates[1]}`}
                  </Typography>
                </Tooltip>
              </Grid>
            </Paper>
          )}
        </>
      )}

      {/* Save */}
      {(props.selectedLayer ||
        props.selectedGeofence ||
        props.selectedMicrofence ||
        props.createEditLayer) &&
        props.searchType?.id !== SearchTypeIDs.Locations &&
        !props.showFilter && (
          <Grid
            container
            style={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              gap: '2%',
              marginLeft: '20px',
              width: '88%',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={!props.layersHaveChanged || props.isLoading}
              onClick={() => {
                if (props.reassignedFences.length === 0) {
                  saveAllChanges();
                } else if (props.reassignedFences.length > 0) {
                  setReassigningToLayer(true);
                }
              }}
            >
              {props.selectedLayer?.id === MICROFENCE_LAYER_ID
                ? 'Save microfence changes'
                : 'Save group changes'}
            </Button>
            <Button
              variant="outlined"
              disabled={!props.layersHaveChanged || props.isLoading}
              onClick={() => cancelAllChanges()}
            >
              Cancel
            </Button>
          </Grid>
        )}

      {/* Dirty */}
      <Dialog
        open={props.dirtySave.isDirty}
        onClose={() => props.setDirtySave({ isDirty: false, issue: null })}
      >
        <DialogTitle>{props.dirtySave.issue}</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              if (
                props.selectedGeofence?.layerId === UNKNOWN_LAYER &&
                props.reassignedFences.length === 0
              ) {
                setFenceNameInput(props.selectedGeofence?.name);
                setBufferOffset(undefined);
                setBufferShape(BufferShapeType.Circle);
                props.setCreateEditFence(RequestType.Edit);
              }
              props.setDirtySave({ isDirty: false, issue: null });
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Deleting Layer */}
      <Dialog open={deleting === 'LAYER'} onClose={() => setDeleting(undefined)}>
        <DialogTitle>Are you sure you want to delete this group?</DialogTitle>
        <DialogContent>
          <p>All associated {getFenceLabel('OUTDOOR')}s will also be deleted.</p>
          <p>This action is irreversible.</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              const olmap = props.mapState?.map;
              if (olmap) {
                await props.deleteLayer(olmap);
              }
              setDeleting(undefined);
              props.setSelectedLayer(undefined);
              props.setCreateEditLayer(undefined);
              props.setAvailableGeofences([]);
              pageRef.current = 1;
              setLayerNameInput('');
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setDeleting(undefined);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Deleting Fence */}
      <Dialog
        open={deleting === EntityType.Geofence || deleting === EntityType.Microfence}
        onClose={() => setDeleting(undefined)}
      >
        <DialogTitle>Are you sure you want to delete this {deleting?.toLowerCase()}?</DialogTitle>
        <DialogActions>
          <Button
            onClick={async () => {
              const olmap = props.mapState?.map;
              if (!olmap) return;
              if (props.selectedGeofence) {
                await props.deleteFence(
                  olmap,
                  props.selectedGeofence,
                  geometryTypeOfEntity(props.selectedGeofence),
                );
              }
              if (props.selectedMicrofence) {
                await props.deleteFence(
                  olmap,
                  props.selectedMicrofence,
                  FenceGeometryType.Microfence,
                );
              }
              props.setLayersHaveChanged(true);
              setDeleting(undefined);
              goBack();
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setDeleting(undefined)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Duplicate Layer */}
      <Dialog open={layerConflict} onClose={() => setLayerConflict(false)}>
        <DialogTitle>A Group with this name already exists. Please choose another one.</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              setLayerConflict(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reassigning Fences */}
      <Dialog open={reassigningToLayer} onClose={() => setReassigningToLayer(false)}>
        <DialogTitle>{`Any unsaved data for ${
          props.reassignedFences.length > 1 ? 'these geofences' : 'this geofence'
        } will be lost`}</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button onClick={() => saveAllChanges()}>OK</Button>
          <Button
            onClick={() => {
              setReassigningToLayer(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Generic Save Changes Dialog */}
      <Dialog open={props.openGenericDialog} onClose={() => props.setOpenGenericDialog(false)}>
        <DialogTitle>Please save group changes before editing this geofence</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              props.setOpenGenericDialog(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload Layer By GeoJSON File */}
      <Dialog
        open={uploadLayerJson}
        onClose={() => setUploadLayerJson(false)}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: 'max-content',
            height: '48%',
          },
        }}
      >
        <>
          {useUploadLayer({
            ...props,
            paginateGeofences,
            uploadLayerJson,
            setUploadLayerJson,
          })}
        </>
      </Dialog>
    </>
  );
};
