import { LocationOn, NearMe, Search } from '@mui/icons-material';
import { PaperBox, SubHeader } from '../../../../Common/Sidebar';
import { Button, Dialog, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Asset, LastKnownLocation, PortableAssetTool, SelectedAsset } from '../../types';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { AUTHED_REQUEST_CONFIG } from '../../../../store/auth';
import { PERSISTOR_URL } from '../../../../store/url';
import { CID, PID } from '../../../../store/user';
import { SaveResult, SAVE_NOTIFICATION } from '../../../../store/notifications';
import { normaliseErrorMessage } from '../../../../util/ErrorMessages';
import { AssetEntity, EntityType } from '../../../../util/enums';
import { addWeeks } from 'date-fns';
import { getAssetType, LastKnownLocationForAsset } from '../../../Tools/LastKnownLocation';
import { StrongFeatureHolder } from '../../../../hooks/geomoby/useLiveMapLoader';
import { Point } from 'ol/geom';
import { MicrofenceData } from '../../Messages';
import { transform } from 'ol/proj';
import { LocationDisplayType, LocationSearchData } from '../../Toolbar/LocationSearch';
import { GridRowData } from '@material-ui/data-grid';
import { MICROFENCE } from '../../BeaconUtils';
import { SECONDARY, truncationStyle } from '../../../../Style/GeoMobyBaseTheme';

export const AssetLastLocation = ({
  asset,
  activeAssets,
  range,
  setRange,
  now,
  setLocationDisplay,
  setLocationSearchData,
  setSelectedTool,
  setSelectedBeacon,
  setSelectedAsset,
  setSelectedMicrofence,
  setSelectedGPSTracker,
  setSelectedDevice,
  microfences,
}: {
  asset: Asset | undefined;
  activeAssets: SelectedAsset[];
  range: [Date | null, Date | null];
  setRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  now: () => Date;
  setLocationDisplay: Dispatch<LocationDisplayType | undefined>;
  setLocationSearchData: Dispatch<LocationSearchData | undefined>;
  setSelectedTool: Dispatch<PortableAssetTool | undefined>;
  setSelectedBeacon: Dispatch<PortableAssetTool | undefined>;
  setSelectedAsset: Dispatch<SelectedAsset | undefined>;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  setSelectedGPSTracker: Dispatch<Asset | undefined>;
  setSelectedDevice: Dispatch<Asset | undefined>;
  microfences: StrongFeatureHolder<Point, MicrofenceData>[];
}) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const [dateNow, setDateNow] = useState<Date>(now());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastKnownLocation, setLastKnownLocation] = useState<LastKnownLocation | undefined>();
  const [showSearchLocationOption, setShowSearchLocationOption] = useState<boolean>(false);
  const [showLastKnownLocationForAsset, setShowLastKnownLocationForAsset] =
    useState<boolean>(false);

  const navigateTo = useCallback(() => {
    if (!lastKnownLocation) {
      setLocationDisplay(undefined);
      setLocationSearchData(undefined);
      return;
    }
    const coords = transform(
      [lastKnownLocation.coordinates.longitude, lastKnownLocation.coordinates.latitude],
      'EPSG:4326',
      'EPSG:3857',
    );
    setLocationSearchData({
      coords,
      isLastKnownLocation: true,
    });
  }, [lastKnownLocation, setLocationDisplay, setLocationSearchData]);

  const getLastKnownLocation = useCallback(async () => {
    setIsLoading(true);
    setLocationDisplay(undefined);
    setLocationSearchData(undefined);

    try {
      setIsLoading(false);
      let lastLocation = (
        await axios.post<LastKnownLocation>(
          `${persistorUrl}/${cid}/${pid}/analytics/last-known-coordinates`,
          {
            fromIso8601: new Date(range[0] ?? '')?.toISOString(),
            toIso8601: new Date(range[1] ?? '')?.toISOString(),
            id:
              asset?.type === AssetEntity.Device
                ? { deviceId: asset?.id }
                : asset?.type === AssetEntity.GPSTracker
                ? { gpsTrackerId: asset?.id }
                : { beaconId: asset?.id },
          },
          authedConfig,
        )
      ).data;

      if (lastLocation) {
        lastLocation = {
          ...lastLocation,
          ids: {
            ...lastLocation.ids,
            label: asset?.label,
          },
        };
      }

      setLastKnownLocation(lastLocation);
      if (!lastLocation) {
        setShowSearchLocationOption(true);
      }
    } catch (error) {
      setIsLoading(false);
      const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Layer);
      setSaveNotification({
        id: SaveResult.FAIL,
        action: '',
        message: errorMessage,
      });
    }
  }, [
    cid,
    pid,
    persistorUrl,
    authedConfig,
    asset,
    range,
    setLastKnownLocation,
    setSaveNotification,
    setLocationDisplay,
    setLocationSearchData,
  ]);

  useEffect(() => {
    getLastKnownLocation();
  }, [getLastKnownLocation]);

  useEffect(() => {
    if (!lastKnownLocation || lastKnownLocation?.ids?.beaconId) return;
    navigateTo();
  }, [lastKnownLocation, navigateTo]);

  // On demount
  useEffect(() => {
    return () => {
      setRange([addWeeks(new Date(dateNow), -1), new Date(dateNow)]);
    };
  }, [setRange, dateNow]);

  return (
    <PaperBox>
      {asset && <SubHeader icon={<LocationOn />}>Last Known Location</SubHeader>}

      {lastKnownLocation && (
        <div style={{ marginLeft: '1rem' }}>
          <Tooltip title={lastKnownLocation.coordinates.latitude.toFixed(5)}>
            <pre style={truncationStyle}>
              lat: {lastKnownLocation.coordinates.latitude.toFixed(5)},
            </pre>
          </Tooltip>
          <Tooltip title={lastKnownLocation.coordinates.longitude.toFixed(5).toString()}>
            <pre style={truncationStyle}>
              lon: {lastKnownLocation.coordinates.longitude.toFixed(5)}
            </pre>
          </Tooltip>
          <Tooltip title={new Date(lastKnownLocation.iso8601).toLocaleString()}>
            <pre style={truncationStyle}>
              at: {new Date(lastKnownLocation.iso8601).toLocaleString()}
            </pre>
          </Tooltip>

          {asset?.type !== AssetEntity.Device && asset?.type !== AssetEntity.GPSTracker && (
            <Grid
              container
              direction="row"
              style={{
                display: 'grid',
                gridTemplateColumns: '90% 10%',
                width: '100%',
              }}
            >
              <Tooltip title={`Sensed By ${lastKnownLocation.sourceIds?.label ?? 'UNKNOWN'}`}>
                <pre style={truncationStyle}>
                  {`Sensed By ${
                    lastKnownLocation.sourceIds?.id
                      ? getAssetType(lastKnownLocation.sourceIds?.id)
                      : ''
                  }`}
                  : {lastKnownLocation.sourceIds?.label ?? 'UNKNOWN'}
                </pre>
              </Tooltip>
              <Grid
                item
                style={{
                  marginTop: '-7px',
                  width: 'fit-content',
                }}
              >
                <Tooltip title={'NAVIGATE TO'}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      navigateTo();
                      setSelectedTool(undefined);
                      setSelectedBeacon(undefined);

                      const activeDevice = activeAssets.find(
                        active =>
                          active.prefix !== MICROFENCE &&
                          JSON.stringify(active.id) ===
                            JSON.stringify(lastKnownLocation?.sourceIds?.id),
                      );
                      const activeMicrofence = activeAssets.find(
                        active =>
                          active.prefix === MICROFENCE &&
                          JSON.stringify(active.id) ===
                            JSON.stringify(lastKnownLocation?.sourceIds?.id),
                      );
                      const foundMicrofence = microfences.find(
                        microfence =>
                          JSON.stringify(microfence.data.assetId) ===
                          JSON.stringify(activeMicrofence?.id),
                      );

                      if (lastKnownLocation?.sourceIds?.id.gatewayId) {
                        setSelectedMicrofence(foundMicrofence);
                        setSelectedAsset(activeMicrofence);
                      } else if (lastKnownLocation?.sourceIds.id.gpsTrackerId) {
                        setSelectedGPSTracker({
                          id: lastKnownLocation.sourceIds?.id.gpsTrackerId as string,
                          label: lastKnownLocation.sourceIds.label ?? '',
                          type: AssetEntity.GPSTracker,
                        });
                        setSelectedAsset(activeDevice);
                      } else if (lastKnownLocation?.sourceIds?.id.deviceId) {
                        setSelectedDevice({
                          id: lastKnownLocation.sourceIds.id.deviceId as string,
                          label: lastKnownLocation.sourceIds.label ?? '',
                          type: AssetEntity.Device,
                        });
                        setSelectedAsset(activeDevice);
                      }
                    }}
                  >
                    <NearMe />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </div>
      )}

      {showSearchLocationOption && !!asset && (
        <div>
          {!lastKnownLocation && (
            <Typography
              style={{
                color: SECONDARY,
              }}
            >
              NO RECENT LAST KNOWN LOCATION
            </Typography>
          )}
          {!lastKnownLocation && (
            <Button
              style={{ marginLeft: '-10px' }}
              onClick={() => setShowLastKnownLocationForAsset(true)}
            >
              <Search />
              <span style={{ fontSize: '10px' }}>Search last location</span>
            </Button>
          )}
          <Dialog
            open={showLastKnownLocationForAsset}
            onClose={() => setShowLastKnownLocationForAsset(false)}
            sx={{
              '& .MuiDialog-paper': {
                maxWidth: '70%',
                maxHeight: '80%',
                width: '650px',
                height: '80%',
              },
            }}
          >
            <LastKnownLocationForAsset
              asset={asset}
              range={range}
              setRange={setRange}
              lastKnownLocation={lastKnownLocation}
              setLastKnownLocation={setLastKnownLocation}
            ></LastKnownLocationForAsset>
          </Dialog>
        </div>
      )}
    </PaperBox>
  );
};
