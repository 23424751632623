import { GridRowData } from '@material-ui/data-grid';
import { FastForward, FirstPage, Pause, PlayArrow, Replay } from '@mui/icons-material';
import { DateTimePicker, LoadingButton } from '@mui/lab';
import {
  Box,
  Fab,
  Grid,
  Paper,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { addWeeks, setSeconds, subHours } from 'date-fns';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Extent } from 'ol/extent';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Header, Item, Sidebar, SubHeader } from '../../../Common/Sidebar';
import { MapRenderer } from '../../../Components/Map/LiveAndReplay/MapRenderer';
import { useGeomobyReplayStream } from '../../../hooks/geomoby/useGeomobyReplayStream';
import { useLiveMapLoader } from '../../../hooks/geomoby/useLiveMapLoader';
import {
  REPLAY_MESSAGES,
  REPLAY_NOW,
  REPLAY_PLAYING,
  REPLAY_SECONDS_PER_SECOND,
  REPLAY_START,
} from '../../../store/replayEvents';
import { RenderAssetState } from '../AssetRenderer';
import { SidebarAndMap } from '../SidebarAndMap/SidebarAndMap';
import { REPLAY_NOTIFICATIONS, SEEN_REPLAY_NOTIFICATIONS } from '../../../store/notifications';
import { LocationDisplayType, LocationSearchData } from '../Toolbar/LocationSearch';
import { MICROFENCE } from '../BeaconUtils';
import { Point } from 'ol/geom';
import { SearchList } from '../LiveAndReplay/Sidebar/Search/SearchList';
import {
  AssetFilter,
  GeofenceFilter,
  LayerFilter,
  MicrofenceFilter,
  Asset,
  PortableAssetTool,
  SearchType,
  ToolFilter,
  ToolType,
  SelectedAsset,
} from '../types';
import { Feature } from 'ol';
import { AssetState } from '../Messages';
import { AssetLatestLocation } from '../SidebarAndMap/Tiles/AssetLastestLocation';
import { TRACKED_DEVICE } from '../../../util/constants';
import { MapType } from '../../../util/enums';
import { KNOWN_TOOLS } from '../../../store/tools';
import { getLocalisedAssets, getSearchSelectOptions } from '../Helpers';

export function ReplayMap() {
  const replayStreamData = useGeomobyReplayStream();
  const replayStreamState = replayStreamData.state;
  const [streamedMicrofences, setStreamedMicrofences] = useState<SelectedAsset[]>([]);
  const [locationDisplay, setLocationDisplay] = useState<LocationDisplayType>();
  const liveMapData = useLiveMapLoader();
  const loadedMicrofences = useMemo(
    () =>
      liveMapData.result
        ? liveMapData.result.microfences.map(({ data }) => ({
            id: data.assetId,
            label: data.fenceName ?? data.fenceId,
          }))
        : [],
    [liveMapData.result],
  );
  const streamedAssets: SelectedAsset[] = useMemo(
    () =>
      getSearchSelectOptions(replayStreamState.assets).map(o => {
        const microfence = loadedMicrofences.find(
          m => JSON.stringify(m.id) === JSON.stringify(o.id),
        );
        const label = microfence?.label ?? o.label;
        return { ...o, label, prefix: microfence ? MICROFENCE : TRACKED_DEVICE };
      }),
    [replayStreamState.assets, loadedMicrofences],
  );

  const resetStateRef = useRef(() => {});

  const [assetFilter, setAssetFilter] = useState<AssetFilter | undefined>();
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [currentCenter, setCurrentCenter] = useState<number[] | undefined>();
  const [deselectFence, setDeselectFence] = useState<boolean>(false);
  const [geofenceFilter, setGeofenceFilter] = useState<GeofenceFilter | undefined>();
  const [microfenceFilter, setMicrofenceFilter] = useState<MicrofenceFilter | undefined>();
  const [layerFilter, setLayerFilter] = useState<LayerFilter | undefined>();
  const [locationSearchData, setLocationSearchData] = useState<LocationSearchData | undefined>();
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [refreshSearch, setRefreshSearch] = useState<boolean>(false);
  const [range, setRange] = useState<[Date | null, Date | null]>([
    addWeeks(new Date(), -1),
    new Date(),
  ]);
  const [searchType, setSearchType] = useState<SearchType | undefined>();
  const [selectedAsset, setSelectedAsset] = useState<SelectedAsset | undefined>();
  const [selectedBeacon, setSelectedBeacon] = useState<Asset | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<Asset | undefined>();
  const [selectedGeofence, setSelectedGeofence] = useState<GridRowData | undefined>();
  const [selectedGPSTracker, setSelectedGPSTracker] = useState<Asset | undefined>();
  const [selectedMicrofence, setSelectedMicrofence] = useState<GridRowData | undefined>();
  const [selectedTool, setSelectedTool] = useState<PortableAssetTool | undefined>();
  const [selectedFromMap, setSelectedFromMap] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [toolFilter, setToolFilter] = useState<ToolFilter | undefined>();
  const [toolTypes, setToolTypes] = useState<ToolType[]>([]);
  const [userExtent, setUserExtent] = useState<Extent | undefined>();
  const initialStartDateTime = useMemo(() => setSeconds(subHours(new Date(), 2), 0), []);
  const [startDateTime, setStartDateTime] = useState<Date | null>(initialStartDateTime);

  const [now, setNow] = useAtom(REPLAY_NOW);
  const [playing, setPlaying] = useAtom(REPLAY_PLAYING);
  const [replayStart, setReplayStart] = useAtom(REPLAY_START);
  const [secondsPerSecond, setSecondsPerSecond] = useAtom(REPLAY_SECONDS_PER_SECOND);
  const setReplayMessages = useSetAtom(REPLAY_MESSAGES);
  const setReplayNotifications = useSetAtom(REPLAY_NOTIFICATIONS);
  const setSeenReplayNotifications = useSetAtom(SEEN_REPLAY_NOTIFICATIONS);
  const knownTools = useAtomValue(KNOWN_TOOLS);

  useEffect(() => {
    return () => {
      setPlaying(false);
    };
  }, [setPlaying]);

  useEffect(() => {
    setNow(setSeconds(replayStart, 0));
    setRange([addWeeks(new Date(replayStart), -1), new Date(replayStart)]);
  }, [replayStart, setNow]);

  useEffect(() => {
    setReplayStart(initialStartDateTime);
    setRange([addWeeks(new Date(initialStartDateTime), -1), new Date(initialStartDateTime)]);
  }, [setReplayStart, initialStartDateTime]);

  const assetIsSelected = (): boolean => {
    return (
      !!selectedAsset ||
      !!selectedBeacon ||
      !!selectedDevice ||
      !!selectedGeofence ||
      !!selectedGPSTracker ||
      !!selectedMicrofence ||
      !!selectedTool
    );
  };

  return (
    <SidebarAndMap
      banner={
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          p={1}
          bgcolor="background.paper"
        >
          <Typography variant="h6" style={{ minWidth: 210 }}>
            {now.toLocaleString()}
          </Typography>
          <Fab color="secondary" size="small" onClick={() => setPlaying(value => !value)}>
            {playing ? <Pause /> : <PlayArrow />}
          </Fab>
        </Stack>
      }
      sidebar={
        <Sidebar>
          <Item>
            <Header icon={<Replay />}>Replay</Header>
            <Paper>
              <Box p={2}>
                <Item>
                  <SubHeader icon={<FirstPage />}>Set playback start</SubHeader>
                  <Stack direction="row" spacing={1}>
                    <DateTimePicker
                      disableFuture
                      value={startDateTime}
                      onChange={setStartDateTime}
                      label="Date and time"
                      renderInput={params => <TextField {...params} />}
                    />
                    <Tooltip title="The replay will be setup to start at this point">
                      <LoadingButton
                        onClick={() => {
                          setReplayMessages([]);
                          setReplayNotifications([]);
                          setSeenReplayNotifications({});
                          setPlaying(false);
                          if (startDateTime && !isNaN(startDateTime.getTime())) {
                            setReplayStart(setSeconds(startDateTime, 0));
                          }
                          resetStateRef.current();
                        }}
                        size="large"
                        color="secondary"
                        variant="contained"
                        disabled={!startDateTime || isNaN(startDateTime.getTime())}
                      >
                        Reset
                      </LoadingButton>
                    </Tooltip>
                  </Stack>
                  <SubHeader icon={<FastForward />}>Set playback speed</SubHeader>
                  <Box p={1}>
                    <Slider
                      value={Math.log2(secondsPerSecond)}
                      min={0}
                      valueLabelDisplay="auto"
                      valueLabelFormat={value => `${Math.pow(2, value)} seconds per second`}
                      max={7}
                      step={null}
                      marks={Array(8)
                        .fill(1)
                        .map((_, value) => ({
                          value,
                          label: `${Math.pow(2, value)}x`,
                        }))}
                      onChange={(_, value) => setSecondsPerSecond(Math.pow(2, Number(value)))}
                    />
                  </Box>
                </Item>
              </Box>
            </Paper>
          </Item>
          <Item>
            {liveMapData.result && (
              <SearchList
                layers={liveMapData.result.layers
                  .map(({ id, name }) => ({ id, name }))
                  ?.sort((a, b) => a.name.localeCompare(b.name))}
                microfences={liveMapData.result.microfences.sort((a, b) =>
                  a.feature.get('name').localeCompare(b.feature.get('name')),
                )}
                selectedBeacon={selectedBeacon}
                setSelectedBeacon={setSelectedBeacon}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                selectedGeofence={selectedGeofence}
                setSelectedGeofence={setSelectedGeofence}
                selectedMicrofence={selectedMicrofence}
                setSelectedMicrofence={setSelectedMicrofence}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                selectedGPSTracker={selectedGPSTracker}
                setSelectedGPSTracker={setSelectedGPSTracker}
                setToolTypes={setToolTypes}
                setExtent={setUserExtent}
                searchType={searchType}
                setSearchType={setSearchType}
                layerFilter={layerFilter}
                setLayerFilter={setLayerFilter}
                assetFilter={assetFilter}
                setAssetFilter={setAssetFilter}
                toolFilter={toolFilter}
                toolTypes={toolTypes}
                setToolFilter={setToolFilter}
                geofenceFilter={geofenceFilter}
                setGeofenceFilter={setGeofenceFilter}
                microfenceFilter={microfenceFilter}
                setMicrofenceFilter={setMicrofenceFilter}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                refreshSearch={refreshSearch}
                setRefreshSearch={setRefreshSearch}
                activeAssets={streamedAssets}
                selectedAsset={selectedAsset}
                setSelectedAsset={setSelectedAsset}
                setLocationSearchData={setLocationSearchData}
                currentCenter={currentCenter}
                replayStartDateTime={startDateTime}
                locationDisplay={locationDisplay}
                setLocationDisplay={setLocationDisplay}
                setNavigateTo={setNavigateTo}
                setSelectedFromMap={setSelectedFromMap}
                setDeselectFence={setDeselectFence}
              ></SearchList>
            )}
            <>
              <AssetLatestLocation
                assetStates={replayStreamState.assets.map(asset => asset.assetState)}
                microfences={liveMapData.result ? liveMapData.result.microfences : []}
                activeAssets={streamedAssets}
                range={range}
                setRange={setRange}
                now={() => now}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                selectedGPSTracker={selectedGPSTracker}
                setSelectedGPSTracker={setSelectedGPSTracker}
                setSelectedMicrofence={setSelectedMicrofence}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                selectedBeacon={selectedBeacon}
                setSelectedBeacon={setSelectedBeacon}
                selectedAsset={selectedAsset}
                setSelectedAsset={setSelectedAsset}
                setLocationDisplay={setLocationDisplay}
                setLocationSearchData={setLocationSearchData}
              ></AssetLatestLocation>
            </>
            {assetIsSelected() &&
              getLocalisedAssets(selectedAsset, replayStreamState.assets, selectedGeofence).map(
                (localisedAsset: AssetState, index) =>
                  RenderAssetState(localisedAsset, knownTools, !selectedAsset, index),
              )}
          </Item>
        </Sidebar>
      }
      map={
        <MapRenderer
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          streamedData={replayStreamData}
          selectedGeofence={selectedGeofence}
          setSelectedGeofence={setSelectedGeofence}
          selectedMicrofence={selectedMicrofence}
          setSelectedMicrofence={setSelectedMicrofence}
          setSelectedBeacon={setSelectedBeacon}
          setSelectedDevice={setSelectedDevice}
          setSelectedTool={setSelectedTool}
          setSelectedGPSTracker={setSelectedGPSTracker}
          userExtent={userExtent}
          setUserExtent={setUserExtent}
          onExtentChanged={liveMapData.setBounds}
          liveMapStaticData={liveMapData.result}
          fencesLoading={liveMapData.loading}
          resetStyling={false}
          mapType={MapType.OUTDOOR_REPLAY_MAP}
          resetStateRef={resetStateRef}
          showLocationTraces={true}
          locationSearchData={locationSearchData}
          setCurrentCenter={setCurrentCenter}
          locationDisplay={locationDisplay}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          selectedFromMap={selectedFromMap}
          setSelectedFromMap={setSelectedFromMap}
          deselectFence={deselectFence}
          setDeselectFence={setDeselectFence}
          setLocationDisplay={setLocationDisplay}
          setLocationSearchData={setLocationSearchData}
          getNow={() => now}
        />
      }
    />
  );
}
